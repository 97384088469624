// Resolutions
$large-screen: 990px;
$medium-screen: 672px;
$small-screen: 415px;
$x-small-screen: 215px;

// Primary
$orange: #c96220;
$lightOrange: #D89E6A;
$darkGreen: #182604;
$goldBrown: #182604;
$sand: #d3bc97;

// Background
$pink: #efc8b7;
$cream: #e2d2c1;
$lightCream: #f2e6da;

// Additional on dark green
$midGreen: #2f3b1d;
$lightGreen: #5e6850;

// Additional gray
$dark-gray: rgb(51, 51, 51);
$light-gray: rgb(169, 169, 169);

// Black White
$black: #000000;
$white: #ffffff;

$darkOrange: #c93720;

// Font-size
$xxl-font-size: 6rem;
$xl-font-size: 5.1rem;
$l-font-size: 4.1rem;
$m-font-size: 4rem;
$ms-font-size: 3rem;
$s-font-size: 2rem;
$xs-font-size: 1.5rem;
$xxs-font-size: 1.1rem;
$xxxs-font-size: 0.9rem;

// Spacing
$spacing-extra-large: 4rem;
$spacing-large: 2rem;
$spacing-medium: 1rem;
$spacing-small: 0.5rem;
$spacing-extra-small: 0.3rem;

// Logo size (in px)
$logo-ref-size: 5rem;
$extra-small-logo-size: $logo-ref-size / 2.618;
$small-logo-size: $logo-ref-size / 1.618;
$medium-logo-size: $logo-ref-size;
$large-logo-size: $logo-ref-size * 1.618;
$extra-large-logo-size: $logo-ref-size * 2.618;
$banner-logo-size: $logo-ref-size;

// Paddings
$side-padding: $spacing-large * 1.7 $spacing-large * 2.5;

@mixin paddings {
  padding: $spacing-large $spacing-medium;

  @media (min-width: $large-screen) {
    padding: $spacing-large * 1.7 $spacing-large * 3.4;
  }
}

// Borders
$sand-border: 1px solid $sand;
$light-green-border: 1px solid $lightGreen;
$orange-border: 1px solid $orange;
$cream-border: 1px solid $cream;

$border-radius: 5px;
$border-radius-small: $border-radius;
$border-radius-medium: $border-radius * 1.618;
$border-radius-large: $border-radius * 2.618;

@mixin border {
  border-width: 1px;
  border-style: solid;
}

@mixin border($direction: null) {
  @if $direction == null {
    border-width: 1px;
    border-style: solid;
  } @else {
    border-#{$direction}-width: 1px;
    border-#{$direction}-style: solid;
  }
}

// Fonts
$regular-flecha: "Regular Flecha Bronzea M";
$dm-sans-medium: "DMSans Medium";

@mixin headline-font {
  font-family: $regular-flecha;
  font-size: $m-font-size;
  line-height: $m-font-size;

  @media (min-width: $large-screen) {
    font-size: $xxl-font-size;
    line-height: $xxl-font-size;
  }
}

@mixin extra-large-title-font {
  font-family: $regular-flecha;
  font-size: $m-font-size;
  line-height: $m-font-size;

  @media (min-width: $x-small-screen) {
    font-size: $l-font-size;
    line-height: $l-font-size;
  }

  @media (min-width: $large-screen) {
    font-size: $xl-font-size;
    line-height: $xl-font-size;
  }
}

@mixin large-title-font {
  font-family: $regular-flecha;

  font-size: $xs-font-size * 1.8;
  line-height: $xs-font-size * 1.8;

  @media (min-width: $x-small-screen) {
    font-size: $m-font-size;
    line-height: $m-font-size;
  }

  @media screen and (min-width: $large-screen) {
    font-size: $l-font-size;
    line-height: $l-font-size;
  }
}

@mixin normal-title-font {
  font-family: $regular-flecha;

  font-size: $s-font-size;
  line-height: $s-font-size * 1.2;

  @media (min-width: $large-screen) {
    font-size: $m-font-size;
    line-height: $m-font-size;
  }
}

@mixin large-text-font {
  font-family: $dm-sans-medium;

  font-size: $xs-font-size;
  font-weight: 700;
}

@mixin normal-text-font {
  font-family: $dm-sans-medium;
  font-size: $xxs-font-size;
  line-height: $xs-font-size;

  font-weight: 500;
}

@mixin label-font {
  font-family: $dm-sans-medium;
  font-size: $xxxs-font-size;
}

// Alignment
@mixin center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

@mixin quote {
  font-family: $regular-flecha;

  font-size: $xs-font-size;
  line-height: $xs-font-size;

  @media (min-width: $large-screen) {
    font-size: $s-font-size;
    line-height: $s-font-size;
  }
}

//layers
$first-layer: 10;
$second-layer: 20;
$third-layer: 30;
